import gsap from "gsap"
import { breakpoints } from "../helpers/utils"
import { gsapParams } from "./common"

const selectors = {
  page: '.tpl-careers',
  title: '.tpl-careers__title',
  subtitle: '.tpl-careers__subtitle',
  button: '.tpl-careers__button',
  desc: '.tpl-careers__desc',
  right: '.tpl-careers__right',
  rightTitle: '.tpl-careers__right h1',
  listItems: '.list-jobs li',
}

const setEntry = () => {
  gsap.set(selectors.title, { ...gsapParams.fadeOut })
  gsap.set(selectors.subtitle, { ...gsapParams.fadeOut })
  gsap.set([selectors.button, selectors.desc, selectors.rightTitle, selectors.listItems], { ...gsapParams.fadeOut })
}

const setVisibleEntry = () => {
  gsap.set(selectors.page, { ...gsapParams.fadeIn })
  gsap.set(selectors.title, { ...gsapParams.fadeIn })
  gsap.set(selectors.subtitle, { ...gsapParams.fadeIn })
  gsap.set([selectors.button, selectors.desc, selectors.rightTitle, selectors.listItems], { ...gsapParams.fadeIn })
}

const animateEntry = () => {
  const tl = []
  tl.push(animate())
  tl.push(animateLeft())

  return tl
}

const animate = () => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selectors.page,
      start: 'top 80%',
      end: "10% center",
      once: true
    }
  })

  tl.to(selectors.page, {...gsapParams.fadeIn, duration: 0, ease: 0 }, 0)
  tl.to(selectors.title, {...gsapParams.fadeIn }, 0)
  tl.to(selectors.subtitle, {...gsapParams.fadeIn }, 0)
  tl.to(selectors.desc, { ...gsapParams.fadeIn }, 0)
  tl.to(selectors.button, {...gsapParams.fadeIn }, 0)
  
  return tl
}

const animateLeft = () => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selectors.right,
      start: '-60% center',
      end: "10% center",
      once: true
    }
  })
  
  const isMobile = window.innerWidth < breakpoints.md
  tl.to(selectors.rightTitle, { ...gsapParams.fadeInUp }, 0)
  tl.to(selectors.listItems, { ...gsapParams.fadeInUp, stagger: 0.05 }, isMobile ? .6 : .2)
  
  return tl
}

export {
  setEntry,
  setVisibleEntry,
  animateEntry
}