export const getPageData = (data, page = 'page') => {
  return data?.[page]?.edges?.[0]?.node?.data ? data?.[page]?.edges[0]?.node?.data : null
}

export const getPrismicListDocument = (data, listKey, key) => {
  return data?.[listKey] ? data[listKey].map((item) => item?.[key]?.document ? item[key].document : null).filter((item) => item !== null) : []
}

export const getPrismicDocument = (data, key) => {
  return data?.[key]?.document ? data[key].document : null
}

export const getPrismicEdges = (data, key) => {
  return data?.[key]?.edges ? data[key].edges.map((item) => item?.node ? item.node : null).filter((item) => item !== null) : []
}